import { LINKS } from '@utils/constants';
import cx from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';

import * as styles from './MailingList.module.css';

import FieldGroup from '@components/Form/FieldGroup';
import Input from '@components/Form/Input';
import Label from '@components/Form/Label';
import Content from '@components/Layout/Content/Content';
import Button from '@components/Molecule/Button/Button';
import Arrow from '@components/SVG/Arrow';
import Copy from '@components/Typography/Copy';

const MailingList = ({ className = '' }: { className?: string }) => {
	const [formSent, setFormSent] = useState(false);
	const [formData, setFormData] = useState({});

	const encode = (data: any) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const form = e.target;

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...formData,
			}),
		})
			.then(() => {
				setFormSent(true);
				setFormData({});
			})
			.catch((error) => alert(error));
	};

	const handleChange = (e) => {
		let value;
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	return formSent ? (
		<Content spacing="none">
			<Copy>
				<strong>Your request has been sent.</strong>
				<br />
				Thank you for your interest in UHV Design.
			</Copy>
		</Content>
	) : (
		<>
			<form
				name="mailinglist"
				method="POST"
				data-netlify="true"
				netlify-honeypot="bot-field"
				autoComplete="off"
				id="mailinglist"
				onSubmit={(e) => handleSubmit(e)}
				className={cx(styles.form, {
					[className]: !!className,
				})}>
				<div className={styles.layout}>
					<label hidden>
						Don't fill this out if you're human:{' '}
						<input name="bot-field" />
					</label>

					<div className={styles.lineItem}>
						<FieldGroup>
							<Label name="email" idFor="email">
								Your email address
							</Label>
							<Input
								name="email"
								id="email"
								type="email"
								required
								className={styles.input}
								onChange={(e) => handleChange(e)}
							/>
						</FieldGroup>
					</div>
				</div>

				<Button type="submit">
					Sign up today
					<Arrow />
				</Button>
			</form>
			<p className={styles.disclaimer}>
				We'll only use your data for the purpose of sending this
				newsletter. You can read more about how we use and keep your
				data safe in our{' '}
				<Link to={LINKS.PRIVACY_POLICY}>privacy policy</Link>.
			</p>
		</>
	);
};

export default MailingList;
