// extracted by mini-css-extract-plugin
export var boldFontWeight = "MailingList-module--bold--font-weight--PbEqF";
export var bounds = "MailingList-module--bounds--f5CKM";
export var checkbox = "MailingList-module--checkbox--Bu3DZ";
export var disclaimer = "MailingList-module--disclaimer--pBtyY";
export var fieldGroup = "MailingList-module--field-group--Z4rTT";
export var form = "MailingList-module--form--AOgNl";
export var input = "MailingList-module--input--50wQa";
export var label = "MailingList-module--label--NZyxM";
export var layout = "MailingList-module--layout--jdISc";
export var lgGap = "MailingList-module--lg--gap--A1mtQ";
export var lineItem = "MailingList-module--line-item--mmE4+";
export var mdGap = "MailingList-module--md--gap--1V1wm";
export var normalFontWeight = "MailingList-module--normal--font-weight--Lkd7d";
export var row = "MailingList-module--row--ZV7Y-";
export var smGap = "MailingList-module--sm--gap--JrPg0";