import cx from 'classnames';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { forwardRef } from 'react';

import * as styles from './ImageCTA.module.css';

import Image from '@components/Molecule/Image/Image';
import Arrow from '@components/SVG/Arrow';

const ImageCTA = ({
	image,
	alt,
	to,
	children,
	className = '',
}: {
	image: any;
	alt: string;
	to: string;
	children: React.ReactNode;
	className?: string;
}): JSX.Element => {
	const AnimatedLink = motion(
		forwardRef((props, ref) => <Link {...props} ref={ref}></Link>)
	);

	return (
		<AnimatedLink
			to={to}
			className={cx(styles.link, { [className]: !!className })}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.6 }}
			transition={{ duration: 0.35 }}
			variants={{
				visible: { opacity: 1 },
				hidden: { opacity: 0 },
			}}>
			<div className={styles.card}>
				<Image image={image} alt={alt} className={styles.image} />

				<div className={styles.content}>
					{children}

					<div className={styles.readMore}>
						<p className={styles.tag}>Read more</p>
						<Arrow className={styles.iconArrow} />
					</div>
				</div>
			</div>
		</AnimatedLink>
	);
};

export default ImageCTA;
