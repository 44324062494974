export const getLocalBusinessOrganisationSchemaMarkup = ({
	title,
	description,
	siteUrl,
	image,
}: {
	title?: string;
	description?: string;
	siteUrl?: string;
	image?: string;
}) => ({
	'@context': 'http://schema.org',
	'@graph': [
		{
			'@type': 'LocalBusiness',
			name: title,
			telephone: '+44 (0)1323 811188',
			email: 'sales@uhvdesign.com',
			description: description,
			image: image,
			logo: "https://images.prismic.io/uhvdesign/ZxDb0YF3NbkBXqW8_UHVD_Blue-Orange.png?auto=format,compress",
			address: {
				'@type': 'PostalAddress',
				streetAddress: 'Judges House',
				addressLocality: 'Lewes Road, Laughton',
				addressRegion: 'East Sussex',
				addressCountry: 'UK',
				postalCode: 'BN8 6BN',
			},
			url: siteUrl,
		},
		{
			'@type': 'Organization',
			name: title,
			url: siteUrl,
			description: description,
			image: image,
			logo: "https://images.prismic.io/uhvdesign/ZxDb0YF3NbkBXqW8_UHVD_Blue-Orange.png?auto=format,compress",
			contactPoint: {
				'@type': 'ContactPoint',
				telephone: '+44 (0)1323 811188',
				contactType: 'customer service',
				areaServed: 'GB',
				availableLanguage: 'en',
			},
			sameAs: [
				'https://www.linkedin.com/company/uhv-design-ltd',
				'https://www.youtube.com/user/UHVDesign',
			],
		},
	],
});
