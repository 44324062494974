import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { A11y, Navigation } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import H from '../Typography/H';
import * as styles from './Carousel.module.css';

import Button from '@components/Molecule/Button/Button';
import Arrow from '@components/SVG/Arrow';
import ArrowLeft from '@components/SVG/ArrowLeft';

const Carousel = ({
	children,
	heading,
	as: Component = 'div',
	className = '',
}: {
	children: React.ReactNode[];
	heading: string;
	as?: React.ElementType;
	className?: string;
}): JSX.Element => {
	const AnimatedComponent = motion(
		forwardRef((props, ref) => <Component {...props} ref={ref}></Component>)
	);

	return (
		<AnimatedComponent
			className={cx(styles.carousel, {
				[className]: !!className,
			})}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.6 }}
			transition={{ duration: 0.35 }}
			variants={{
				visible: { opacity: 1 },
				hidden: { opacity: 0 },
			}}>
			<DesktopNavigation heading={heading} />

			<Swiper
				modules={[Navigation, A11y]}
				spaceBetween={50}
				slidesPerView={1.2}
				loop={false}
				breakpoints={{
					1024: {
						slidesPerView: 2.5,
						loop: false,
						spaceBetween: 60,
					},
				}}
				navigation={{
					nextEl: '.right-arrow',
					prevEl: '.left-arrow',
				}}
				className={styles.interaction}>
				{children.map((child, key) => (
					<SwiperSlide key={key}>{child}</SwiperSlide>
				))}
			</Swiper>

			<MobileNavigation />
		</AnimatedComponent>
	);
};

const DesktopNavigation = ({ heading }: { heading: string }) => {
	return (
		<div className={styles.content}>
			{heading && (
				<H level={3} color="blue">
					{heading}
				</H>
			)}

			<div className={cx(styles.buttons, styles.buttonsDesktop)}>
				<Button className="left-arrow">
					<ArrowLeft className={styles.arrow} />
				</Button>
				<Button className="right-arrow">
					<Arrow className={styles.arrow} />
				</Button>
			</div>
		</div>
	);
};

const MobileNavigation = () => {
	return (
		<div className={cx(styles.buttons, styles.buttonsMobile)}>
			<Button className="left-arrow">
				<ArrowLeft className={styles.arrow} />
			</Button>
			<Button className="right-arrow">
				<Arrow className={styles.arrow} />
			</Button>
		</div>
	);
};

export default Carousel;
