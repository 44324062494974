import { LINKS } from '@utils/constants';
import { StaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import Grid from '@components/Layout/Grid/Grid';
import Card from '@components/Molecule/Card/Card';
import H from '@components/Typography/H';

const ApplicationListing = ({
	large,
	exclude,
}: {
	large?: boolean;
	exclude?: string;
}) => {
	return (
		<StaticQuery
			query={graphql`
				query {
					application: allPrismicApplication(
						sort: { order: ASC, fields: data___title }
					) {
						edges {
							node {
								uid
								data {
									title
									application_image {
										gatsbyImageData(width: 320)
										alt
									}
								}
							}
						}
					}
				}
			`}
			render={({ application }: { application: any }): JSX.Element => {
				return (
					<Grid gap="sm">
						{application.edges
							.filter(({ node }) => node.uid !== exclude)
							.map(({ node }, key: number) => {
								const { uid } = node;
								const { title, application_image } = node.data;

								return (
									<Card
										id={key}
										title={title}
										to={`${LINKS.APPLICATIONS}/${uid}`}
										large={large}
										thumbnail={getImage(
											application_image.gatsbyImageData
										)}
										alt={application_image.alt}
										motionDelay={0.03}
										key={key}>
										<H level={5} as="p">
											{title}
										</H>
									</Card>
								);
							})}
					</Grid>
				);
			}}
		/>
	);
};

export default ApplicationListing;
